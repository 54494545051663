import { connect } from 'react-redux';
import ShippingAddress from '../../components/wrappers/ShippingAddress';

import {
  setShippingAddress,
  setBillingAddress,
  setStoreBillingAddressSet,
  setStoreShippingAddressSet,
  setGiftAddress,
  setStoreGiftAddressSet,
  getShippingMethods,
  setShippingInformation,
} from '../../store/actions/checkout';

const mapStateToProps = (state) => ({
  shippingAddress: state.customer.form.shippingAddress,
  billingAddress: state.customer.form.billingAddress,
  giftAddress: state.customer.form.giftAddress,
  addressFields: state.customer.form.addressFields,
  billingAddressSet: state.customer.billingSet,
  shippingAddressSet: state.customer.shippingSet,
  giftAddressSet: state.customer.giftAddressSet,
  customerAddresses: state.customer.customerAddresses,
  deliveryData: state.checkout.deliveryData,
  isExpress: state.checkout.isExpress,
  countries: state.customer.form.countries,
  cartData: state.checkout.cart,
});

const mapDispatchToProps = (dispatch) => ({
  setShippingMethods: (address) => dispatch(getShippingMethods(address)),
  setShippingAddress: (address, billingSameAsShipping, clickandcollect) => dispatch(setShippingAddress(address, billingSameAsShipping, clickandcollect)),
  setShippingInformation: (shippingInformation, currentStep, proceed) => dispatch(setShippingInformation(shippingInformation, currentStep, proceed)),
  setBillingAddress: (address) => dispatch(setBillingAddress(address)),
  setGiftAddress: (address) => dispatch(setGiftAddress(address)),
  setShippingAddressSet: (isSet) => dispatch(setStoreShippingAddressSet(isSet)),
  setBillingAddressSet: (isSet) => dispatch(setStoreBillingAddressSet(isSet)),
  setGiftAddressSet: (isSet) => dispatch(setStoreGiftAddressSet(isSet)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingAddress);
