export default (cartData, isClickAndCollect) => {
  const { items: quoteItems } = cartData;
  let giftProduct = false;
  let giftCardsOnly = true;

  quoteItems.forEach((item) => {
    if (item.shipping_type !== 'gift_card') {
      giftCardsOnly = false;
    }

    if (item.gift_delivery === true) {
      giftProduct = true;
    }
  });

  return (!(!giftProduct || giftCardsOnly)) && isClickAndCollect;
};
