/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
const CheckoutModal = React.lazy(() => import('../../common/CheckoutModal'));
const AddressForm = React.lazy(() => import('../../common/AddressForm'));
import styles from '../Steps/style.module.scss';
import shippingStyles from './style.module.scss';
import Button from '../../common/Button';
import setDataLayer from '../../../helpers/DataLayer';


// Krispy Kreme Only
import { getClickAndCollectDateTime } from '../../../helpers/MageStorage';
import GiftAddress from '../../../helpers/GiftAddress';

const { selectedDeliveryDate = '', selectedDeliveryTime = '' } = getClickAndCollectDateTime();

// eslint-disable-next-line no-undef
const { genecheckout: { giftMessage = '' } = {} } = window || {};

const ShippingAddress = (props) => {
  const {
    currentStep,
    steps,
    setStepValid,
    shippingAddress,
    billingAddress,
    giftAddress,
    addressFields,
    customerAddresses,
    setStep,
    setShippingAddress,
    setBillingAddress,
    setGiftAddress,
    setShippingMethods,
    billingAddressSet,
    giftAddressSet,
    shippingAddressSet,
    setShippingAddressSet,
    setBillingAddressSet,
    setGiftAddressSet,
    isExpress,
    setShippingInformation,
    isClickAndCollect,
    countries,
    cartData,
  } = props;

  const requireGiftAddress = () => GiftAddress(cartData, isClickAndCollect);

  const shippingFormName = isClickAndCollect ? 'Collection Details' : 'Shipping Address';
  const proceedButtonName = isClickAndCollect ? 'Proceed to Payment' : 'Proceed to Shipping Method';

  const [sameAsShipping, setSameAsShipping] = useState(true);

  const stepValid = requireGiftAddress()
    ? (giftAddressSet && billingAddressSet && shippingAddressSet)
    : (billingAddressSet && shippingAddressSet);

  const { order } = steps;
  const thisStepIndex = order.findIndex((step) => step.name === 'shippingAddress');
  const isStep = (currentStep === thisStepIndex);
  const isReady = (currentStep >= thisStepIndex);

  const applyShippingAddress = (address) => {
    if (isClickAndCollect) {
      setShippingAddress(address, false, true);
      return;
    }

    setShippingAddress(address, sameAsShipping, false);
  };

  const applyBillingAddress = (address) => {
    setBillingAddress(address);
  };

  const applyGiftAddress = (address) => {
    setGiftAddress(address);
  };

  const changeBillingAddress = (set) => {
    setSameAsShipping(set);
  };

  const progress = async () => {
    setStepValid('shippingAddress', true);

    // Krispy Kreme Only
    // Automatically set the method and proceed to payment
    if (isClickAndCollect) {
      const shippingInformation = {
        shippingAddress,
        billingAddress,
      };

      shippingInformation.methodCode = 'STORE';
      shippingInformation.carrierCode = 'kkcc';
      setShippingInformation(shippingInformation, currentStep + 1, !isExpress);
    } else {
      setStep(currentStep + 1);
      await setShippingMethods(shippingAddress);
    }
  };

  const [cancelCheckoutDialog, setCancelCheckoutDialog] = useState(false);
  const confirmCheckoutCancellation = () => {

    // If step is greater then 2, then reset the step back to 2
    if (currentStep > 2) {
      setStep(2);
    }

    let url = window.deliveryData.resetUrl ? window.deliveryData.resetUrl : '/';
    window.location.href = url + '?resetDelivery=true';
  };

  const FormattedDateTime = () => {
    let formattedDate;

    if (selectedDeliveryDate && selectedDeliveryTime) {
      formattedDate = new Date(selectedDeliveryDate);
      formattedDate = new Intl.DateTimeFormat('en-GB').format(formattedDate);

      return (
        <>
          {`Please collect your order from the above store on ${formattedDate} at ${selectedDeliveryTime}.`}
        </>
      );
    }

    return (<></>);
  };

  //changing the step of the datalayer according to the step of the checkout
  useEffect(() => {
    if(isStep){
        const option = isClickAndCollect ? 'Click and Collect' : 'Home Delivery';
        setDataLayer(cartData, currentStep, option);
    }
  }, [currentStep, isClickAndCollect]);

  return (
    <div className={styles.step__inner}>
      {isReady && (
        <Suspense fallback={<div>Loading...</div>}>
          <CheckoutModal
            isOpen={cancelCheckoutDialog}
            onRequestClose={() => { setCancelCheckoutDialog(false); }}
            contentLabel="Change my delivery details"
            modalTitle="Change my delivery details"
            modalContent="Are you sure you want to return to your basket to update your delivery?"
            exitModal={() => { setCancelCheckoutDialog(false); }}
            confirmAction={() => { confirmCheckoutCancellation(); }}
            showActions
            confirmLabel="Yes, change my details"
          />
          <div>
            <div className={shippingStyles.deliveryType}>
              <p>
                You selected:&nbsp;
                {isClickAndCollect ? 'Click and Collect' : 'Home Delivery'}
              </p>
              <Button
                trigger={() => { setCancelCheckoutDialog(true); }}
                label="Change my delivery details"
                buttonStyle="link"
              />
            </div>
            <div>
              <div className={shippingStyles.addressWrapper}>
                <div className={shippingStyles.address}>
                  <AddressForm
                    onSubmit={applyShippingAddress}
                    onEdit={setShippingAddressSet}
                    address={shippingAddress}
                    addressFields={addressFields}
                    name={shippingFormName}
                    isSet={shippingAddressSet}
                    canEditAddress={!isClickAndCollect}
                    countries={countries}
                    isClickAndCollect={isClickAndCollect}
                    customerAddresses={customerAddresses}
                    isShippingAddress
                  />
                  {isClickAndCollect && (
                    <FormattedDateTime />
                  )}
                  {!isClickAndCollect && (
                    <div className={styles.step__edit}>
                      <input
                        name="sameAsShipping"
                        id="sameAsShipping"
                        type="checkbox"
                        onChange={() => changeBillingAddress(!sameAsShipping)}
                        checked={sameAsShipping}
                      />
                      <label htmlFor="sameAsShipping">
                        Billing is the same as shipping
                      </label>
                    </div>
                  )}
                  {giftMessage && (
                    <div className={shippingStyles.giftMessage}>
                      <p>{giftMessage}</p>
                    </div>
                  )}
                </div>
                <div className={shippingStyles.address}>
                  {(!sameAsShipping || isClickAndCollect) && (
                    <>
                      <AddressForm
                        onSubmit={applyBillingAddress}
                        onEdit={setBillingAddressSet}
                        address={billingAddress}
                        addressFields={addressFields}
                        name="Billing Details"
                        isSet={billingAddressSet}
                        stepValid={stepValid}
                        countries={countries}
                        customerAddresses={customerAddresses}
                      />
                    </>
                  )}
                </div>
                {requireGiftAddress() && (
                  <div className={shippingStyles.address}>
                    <AddressForm
                      onSubmit={applyGiftAddress}
                      onEdit={setGiftAddressSet}
                      address={giftAddress}
                      addressFields={addressFields}
                      name="Other Delivery Address"
                      isSet={giftAddressSet}
                      stepValid={stepValid}
                      countries={countries}
                      customerAddresses={customerAddresses}
                      additionalText="Specify a separate delivery address for the Display Tower/Stand or Gift Card within your order."
                    />
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className={styles.step__nav}>
            {stepValid && (
              <button className="button-proceed" type="button" onClick={() => progress()}>
                {proceedButtonName}
              </button>
            )}
          </div>
        </Suspense>
      )}
    </div>
  );
};

ShippingAddress.propTypes = {
  currentStep: PropTypes.number,
  setStepValid: PropTypes.func,
  setStep: PropTypes.func,
  shippingAddress: PropTypes.shape({}),
  billingAddress: PropTypes.shape({}),
  giftAddress: PropTypes.shape({}),
  setBillingAddress: PropTypes.func,
  setShippingAddress: PropTypes.func,
  setGiftAddress: PropTypes.func,
  setShippingMethods: PropTypes.func,
  shippingAddressSet: PropTypes.bool,
  billingAddressSet: PropTypes.bool,
  giftAddressSet: PropTypes.bool,
  setShippingAddressSet: PropTypes.func,
  setBillingAddressSet: PropTypes.func,
  setGiftAddressSet: PropTypes.func,
  setShippingInformation: PropTypes.func,
  customerAddresses: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  steps: PropTypes.shape({
    order: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isValid: PropTypes.bool,
      isEditing: PropTypes.bool,
    })),
    current: PropTypes.number,
  }),
  isClickAndCollect: PropTypes.bool,
  isExpress: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.shape({
    full_name_english: PropTypes.string,
    two_letter_abbreviation: PropTypes.string,
  })),
  addressFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
  })),
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ShippingAddress.defaultProps = {
  currentStep: 0,
  setStepValid: () => {},
  setStep: () => {},
  shippingAddress: {},
  billingAddress: {},
  giftAddress: {},
  setBillingAddress: () => {},
  setShippingAddress: () => {},
  setGiftAddress: () => {},
  setShippingMethods: () => {},
  shippingAddressSet: false,
  billingAddressSet: false,
  giftAddressSet: false,
  setShippingAddressSet: () => {},
  setBillingAddressSet: () => {},
  setGiftAddressSet: () => {},
  setShippingInformation: () => {},
  customerAddresses: [],
  steps: {
    current: 0,
    order: [],
  },
  isClickAndCollect: false,
  isExpress: false,
  countries: [],
  addressFields: [],
  cartData: {
    cart_type: '',
    items: [],
  },
};

export default ShippingAddress;
